'use client';

import { useEffect } from 'react';

import { red60 } from '@carbon/colors';
import { CloseOutline } from '@carbon/icons-react';
import { Button, Link as CarbonLink, Stack, Tile } from '@carbon/react';
import Link from 'next/link';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    window.newrelic?.noticeError(error);
  }, [error]);

  return (
    <Tile>
      <Stack gap={6}>
        <h2>
          <CloseOutline size={24} color={red60} /> Something went wrong!
        </h2>
        <p>
          <Button
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            Try again
          </Button>
        </p>
        <p>
          <Link href="/">
            <CarbonLink>Return to the homepage</CarbonLink>
          </Link>
        </p>
      </Stack>
    </Tile>
  );
}
